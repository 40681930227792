<template>
  <div class="clock">
    <div class="text-center">{{ nowDateTime.format('YYYY/MM/DD dddd') }}</div>
    <div class="text-center" style="margin-top: 0.5rem">
      <v-sheet rounded outlined class="time-box">
        <span class="text-h3" style="margin-right: 0.2rem">{{
          nowDateTime.format('HH:mm')
        }}</span>
        <span>{{ nowDateTime.format('A') }}</span>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { countdownReload } from '@/shared/utils'
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {
      nowDateTime: moment()
    }
  },
  computed: {
    ...mapGetters({
      serverTime: 'schoolDashboard/serverTime'
    })
  },
  watch: {
    serverTime(val) {
      this.nowDateTime = moment(val)
    }
  },
  created: function () {
    moment.updateLocale('en', {
      weekdays: ['週日', '週一', '週二', '週三', '週四', '週五', '週六']
    })
  },
  mounted: function () {
    this.timer = countdownReload(this.updateDateTime, 1)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    updateDateTime() {
      this.nowDateTime = moment(this.nowDateTime.add(1, 'second'))
    }
  }
}
</script>

<style lang="scss" scoped>
.time-box {
  margin: 0.5rem 1rem 1rem 1rem;
  padding: 0.5rem 0.8rem;
  border: 0.08rem solid #d4d4d4 !important;
}
</style>
