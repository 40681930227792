<template>
  <v-card class="elevation-0" outlined>
    <div
      class="rounded text-center"
      :style="{
        backgroundColor: infoTitleBackgroundColor,
        color: infoTitleColor,
        margin: '0.5rem',
        padding: '0.5rem'
      }"
    >
      校園能源資訊
    </div>
    <weather style="margin-top: 0.5rem" />
    <clock style="margin-top: 1.5rem" />
    <div style="margin: 1.5rem 1rem 1rem 1rem">
      <slot name="infoBox"></slot>
    </div>
  </v-card>
</template>

<script>
import weather from './weather'
import clock from './clock'

export default {
  name: undefined,
  mixins: [],
  components: {
    weather,
    clock
  },
  props: {
    infoTitleColor: {
      type: String,
      default: '#683e0c'
    },
    infoTitleBackgroundColor: {
      type: String,
      default: '#e5dcb7'
    }
  },
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.info-box {
  .main-title {
    font-size: 1.125rem;
    padding: 0.5vw 0;
  }
}
</style>
