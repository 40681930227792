<template>
  <div class="main-canvas">
    <div class="title font-weight-bold text-h3 text-center">
      太陽在學校可以製造多少電？
    </div>
    <img
      class="solar"
      :src="require('@/assets/image/schoolDashboard/group-56.svg')"
    />
    <div class="message-box">
      <div>
        我現正發功
        <span style="color: #f15a24; font-size: 2rem">{{
          solar.energyPerMin === null
            ? '--'
            : numeral(solar.energyPerMin).format('0,0')
        }}</span>
        kW 喔！
      </div>
    </div>
    <img
      v-if="solar"
      class="message-box-triangle"
      :src="require('@/assets/image/schoolDashboard/path-80.svg')"
    />
    <img
      class="battery-bg"
      :src="require('@/assets/image/schoolDashboard/group-60.svg')"
    />
    <img
      class="battery-outline"
      :src="require('@/assets/image/schoolDashboard/group-58.svg')"
    />
    <div class="battery-power" :style="batteryPowerStyle">
      <div v-if="solar && solar.pvCapacityPercent">
        {{ numeral(solar.pvCapacityPercent).format('0%') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      solar: 'schoolDashboard/solar'
    }),
    batteryPowerStyle() {
      if (!this.solar || !this.solar.pvCapacityPercent) {
        return null
      }
      return `background: linear-gradient(0deg, #07FF9F 0, #36BDFF ${numeral(
        this.solar.pvCapacityPercent
      ).format('0%')}, #335874 ${numeral(this.solar.pvCapacityPercent).format(
        '0%'
      )}, #335874 100%);`
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-card {
  position: relative;
  .solar {
    position: absolute;
    top: 22vw;
    left: 46vw;
    z-index: 1000;
    width: 40vw;
  }
  .message-box {
    position: absolute;
    top: 16vw;
    left: 36vw;
    z-index: 1000;
  }
  .message-box-triangle {
    position: absolute;
    top: 23vw;
    left: 46vw;
    z-index: 1001;
    width: 9vw;
  }
  .battery-bg {
    position: absolute;
    top: 12.5vw;
    left: 0;
    z-index: 900;
    width: 46vw;
  }
  .battery-outline {
    position: absolute;
    top: 17vw;
    left: 13vw;
    z-index: 903;
    width: 16vw;
  }
  .battery-power {
    position: absolute;
    top: 20.2vw;
    left: 14vw;
    z-index: 901;
    background-color: #335874;
    height: 25vw;
    line-height: 25vw;
    width: 14vw;
    > div {
      font-size: 2rem;
      color: #fff;
      text-align: center;
      text-shadow: 2px 3px 2px #333;
    }
  }
  .title {
    color: #683e0c;
  }
}
</style>
