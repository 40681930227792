<template>
  <mainTemplate>
    <template v-slot:infoCard>
      <compareInfo class="info-card" />
    </template>
    <template v-slot:mainCard>
      <energyCompare class="main-card" />
    </template>
  </mainTemplate>
</template>

<script>
import mainTemplate from './mainTemplate'
import compareInfo from './card/compareInfo'
import energyCompare from './mainCanvas/energyCompare'

export default {
  name: undefined,
  mixins: [],
  components: {
    compareInfo,
    energyCompare,
    mainTemplate
  },
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.schoolDashboard-card::v-deep {
  background-color: #edf7f2;
  .v-sheet--outlined {
    border: 0.1rem solid #bbe8e8;
  }
  .main-title {
    color: #107766;
  }
}
</style>
