<template>
  <div>
    <div class="message-box" v-if="value">
      <div>
        現在<span style="color: #f15a24">冷氣的用電</span>都是我提供的！
      </div>
    </div>
    <img
      v-if="value"
      class="message-box-triangle"
      :src="require('@/assets/image/schoolDashboard/path-80.svg')"
    />
    <img
      class="libra"
      :src="require('@/assets/image/schoolDashboard/Group 166.svg')"
    />
    <img
      class="weight-solar"
      :src="require('@/assets/image/schoolDashboard/group-164.svg')"
    />
    <img
      class="weight-ac"
      :src="require('@/assets/image/schoolDashboard/group-157.svg')"
    />
    <div class="energy-value weight-solar-value">
      <div>{{ value.solar }}<br /><span class="unit">kW</span></div>
    </div>
    <div class="energy-value weight-ac-value">
      <div>{{ value.ac }}<br /><span class="unit">kW</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.message-box {
  position: absolute;
  top: 8vw;
  left: 1vw;
  z-index: 1000;
  font-size: 2.1vw !important;
  width: 30vw;
}
.message-box-triangle {
  position: absolute;
  top: 14vw;
  left: 22vw;
  z-index: 1001;
  width: 9vw;
}
.libra {
  position: absolute;
  top: 21vw;
  left: 0;
  z-index: 1003;
  width: 50vw;
}
.weight-solar {
  position: absolute;
  width: 9vw;
  top: 22.5vw;
  left: 3.2vw;
}
.weight-power {
  position: absolute;
  width: 7vw;
  top: 33.5vw;
  left: 41vw;
}
.weight-ac {
  position: absolute;
  width: 7vw;
  top: 13vw;
  left: 39.5vw;
  z-index: 1001;
}
.weight-solar-value {
  top: 27vw;
  left: 4.5vw;
}
.weight-power-value {
  top: 38vw;
  left: 41.3vw;
  background: none !important;
  color: #fff;
  text-shadow: 0px 0px 3px black;
}
.weight-ac-value {
  top: 18.5vw;
  left: 39.8vw;
}
</style>
