<template>
  <infoTemplate>
    <template v-slot:infoBox>
      <solarBox />
      <v-divider style="margin: 1rem auto"></v-divider>
      <solarPVBox />
    </template>
  </infoTemplate>
</template>

<script>
import infoTemplate from './infoTemplate'
import solarBox from '../infoBox/solarBox'
import solarPVBox from '../infoBox/solarPVBox'

export default {
  name: undefined,
  mixins: [],
  components: {
    infoTemplate,
    solarBox,
    solarPVBox
  },
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-title {
  color: #683e0c !important;
}
.info-box {
  .main-title {
    font-size: 1.125rem;
    padding: 0.5vw 0;
  }
}
</style>
