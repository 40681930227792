<template>
  <div class="schoolDashboard-card">
    <v-row class="ma-0">
      <v-col cols="auto" style="width: 25%">
        <slot name="infoCard"></slot>
      </v-col>
      <v-col>
        <banner />
        <slot name="mainCard"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import banner from './card/banner'

export default {
  name: undefined,
  mixins: [],
  components: {
    banner
  },
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>
