<template>
  <div>
    <div class="text-center text-h6 font-weight-bold">
      <img
        v-if="weather && (weather.temperature || weather.humidity)"
        style="vertical-align: text-bottom; width: 2.5vw; margin-right: 0.5vw"
        :src="require('@/assets/image/schoolDashboard/group-44.svg')"
      />
      <span v-if="weather && weather.temperature">
        {{ numeral(weather.temperature).format('0') }}°C
      </span>
      <span
        v-if="weather && weather.temperature && weather.humidity"
        style="padding: 0 0.5vw"
      >
        /
      </span>
      <span v-if="weather && weather.humidity">{{
        numeral(weather.humidity).format('0%')
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      weather: 'schoolDashboard/weather'
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style></style>
