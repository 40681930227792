<template>
  <div class="info-box">
    <div class="main-title">本校太陽能發電裝置</div>
    <v-row>
      <v-col cols="auto">裝置容量：</v-col
      ><v-col
        >{{
          Object.keys(solar).length === 0 || solar.schoolPvCapacity === null
            ? '--'
            : numeral(solar.schoolPvCapacity).format('0[.]00')
        }}
        kW</v-col
      >
      <v-col cols="12" class="pa-0"></v-col>
      <v-col cols="auto">屋頂光電：</v-col
      ><v-col
        >{{
          Object.keys(solar).length === 0 || solar.roofPvCapacity === null
            ? '--'
            : numeral(solar.roofPvCapacity).format('0[.]00')
        }}
        kW</v-col
      >
      <v-col cols="12" class="pa-0"></v-col>
      <v-col cols="auto">風雨球場：</v-col
      ><v-col
        >{{
          Object.keys(solar).length === 0 || solar.fieldPvCapacity === null
            ? '--'
            : numeral(solar.fieldPvCapacity).format('0[.]00')
        }}
        kW</v-col
      >
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      solar: 'schoolDashboard/solar'
    })
  }
}
</script>

<style></style>
