<template>
  <mainTemplate>
    <template v-slot:infoCard>
      <compareInfo
        class="info-card"
        infoTitleColor="#383838"
        infoTitleBackgroundColor="#a7daf4"
      />
    </template>
    <template v-slot:mainCard>
      <reduceCO2 class="main-card" />
    </template>
  </mainTemplate>
</template>

<script>
import mainTemplate from './mainTemplate'
import compareInfo from './card/compareInfo'
import reduceCO2 from './mainCanvas/reduceCO2'

export default {
  name: undefined,
  mixins: [],
  components: {
    compareInfo,
    reduceCO2,
    mainTemplate
  },
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.schoolDashboard-card::v-deep {
  background-color: #dfeff7;
  .v-sheet--outlined {
    border: 0.1rem solid #bbe8e8;
  }
  .main-title {
    color: #005272;
  }
}
</style>
