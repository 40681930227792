<template>
  <mainTemplate>
    <template v-slot:infoCard>
      <solarInfo class="info-card" />
    </template>
    <template v-slot:mainCard>
      <solarMakeEnergy class="main-card" />
    </template>
  </mainTemplate>
</template>

<script>
import mainTemplate from './mainTemplate'
import solarInfo from './card/solarInfo'
import solarMakeEnergy from './mainCanvas/solarMakeEnergy'

export default {
  name: undefined,
  mixins: [],
  components: {
    solarInfo,
    solarMakeEnergy,
    mainTemplate
  },
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.schoolDashboard-card::v-deep {
  background-color: #fffae6;
  overflow: hidden;
  .v-sheet--outlined {
    border: 0.1rem solid #e5dcb7;
  }
  .primary--text {
    color: #726434;
  }
  .v-divider {
    border-top: 0;
    border-bottom: 0.08rem solid #c0bf8c;
    border-radius: inherit;
  }
  .main-title {
    color: #683e0c;
  }
}
</style>
