<template>
  <div>
    <div class="message-box" v-if="value">
      <div>
        現在還剩
        <span style="color: #f15a24; font-size: 2rem">{{
          numeral(surplusPower).format('0,0')
        }}</span>
        kW！
      </div>
    </div>
    <img
      v-if="value"
      class="message-box-triangle"
      :src="require('@/assets/image/schoolDashboard/path-80.svg')"
    />
    <img
      class="libra"
      :src="require('@/assets/image/schoolDashboard/Group 166.svg')"
    />
    <img
      class="weight-solar"
      :src="require('@/assets/image/schoolDashboard/group-164.svg')"
    />
    <img
      class="weight-power"
      :src="require('@/assets/image/schoolDashboard/group-143.svg')"
    />
    <img
      class="weight-ac"
      :src="require('@/assets/image/schoolDashboard/group-157.svg')"
    />
    <div class="energy-value weight-solar-value">
      <div>{{ value.solar }}<br /><span class="unit">kW</span></div>
    </div>
    <div class="energy-value weight-power-value">
      <div>{{ value.power }}<br /><span class="unit">kW</span></div>
    </div>
    <div class="energy-value weight-ac-value">
      <div>{{ value.ac }}<br /><span class="unit">kW</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {}
  },
  computed: {
    surplusPower() {
      return numeral(
        this.value.solar - (this.value.power + this.value.ac)
      ).format('0.00')
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.message-box {
  position: absolute;
  top: 9vw;
  left: 6vw;
  z-index: 1000;
  font-size: 2.1vw !important;
}
.message-box-triangle {
  position: absolute;
  top: 16vw;
  left: 20vw;
  z-index: 1001;
  width: 9vw;
}
.libra {
  position: absolute;
  top: 21vw;
  left: 0;
  z-index: 900;
  width: 50vw;
}
.weight-solar {
  position: absolute;
  width: 13vw;
  top: 19.5vw;
  left: 1.2vw;
}
.weight-power {
  position: absolute;
  width: 6vw;
  top: 13vw;
  left: 36vw;
}
.weight-ac {
  position: absolute;
  width: 6vw;
  top: 13vw;
  left: 43vw;
}
.weight-solar-value {
  top: 27vw;
  left: 4.5vw;
}
.weight-power-value {
  top: 19vw;
  left: 35.8vw;
}
.weight-ac-value {
  top: 19vw;
  left: 42.75vw;
}
</style>
