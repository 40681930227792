<template>
  <div class="main-canvas">
    <div class="title font-weight-bold text-h3 text-center">
      怎麼做才能節電呢？
    </div>
    <img
      class="solar"
      :src="require('@/assets/image/schoolDashboard/Group 163.svg')"
    />
    <div class="message-box">
      <div>夏天時冷氣最吃電，改用變頻冷氣搭配電扇才能省電～</div>
    </div>
    <img
      class="message-box-triangle"
      :src="require('@/assets/image/schoolDashboard/path-80.svg')"
    />
    <img
      class="main-bg"
      :src="require('@/assets/image/schoolDashboard/Group 276.svg')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      CO2: 'schoolDashboard/CO2'
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-card {
  position: relative;
  .solar {
    position: absolute;
    top: 22vw;
    left: 46vw;
    z-index: 1000;
    width: 40vw;
  }
  .message-box {
    position: absolute;
    top: 8vw;
    left: 29vw;
    z-index: 1000;
    width: 33vw;
    font-size: 2.1vw;
  }
  .message-box-triangle {
    position: absolute;
    top: 17vw;
    left: 46vw;
    z-index: 1001;
    width: 9vw;
  }
  .main-bg {
    position: absolute;
    top: 13vw;
    left: 1vw;
    z-index: 900;
    width: 41vw;
  }
  .title {
    color: #005272;
  }
}
</style>
