<template>
  <v-sheet class="banner" color="transparent">
    <v-row align="center">
      <v-col><v-divider></v-divider></v-col>
      <v-col cols="auto" style="line-height: normal">
        <img
          style="width: 29vw; padding: 0 1rem"
          :src="require('@/assets/image/schoolDashboard/group-43.svg')"
        />
      </v-col>
      <v-col><v-divider></v-divider></v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
