<template>
  <v-container
    fluid
    fill-height
    class="page404 justify-center"
    v-if="loadingSchoolInfo || loadingData"
  >
    <v-progress-circular
      indeterminate
      class="text-center"
      color="grey"
      size="50"
    />
  </v-container>
  <div v-else>
    <div id="schoolDashboard" v-if="schoolInfo.schoolId">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-if="whetherSolarMeter">
            <solarMakeEnergy />
          </div>
          <div class="swiper-slide">
            <useEnergy />
          </div>
          <div class="swiper-slide" v-if="whetherSolarMeter">
            <energyCompare />
          </div>
          <div class="swiper-slide" v-if="whetherSolarMeter">
            <reduceCO2 />
          </div>
          <div class="swiper-slide">
            <energyConservation />
          </div>
        </div>
      </div>
    </div>
    <v-container fluid fill-height class="page404" v-else>
      <v-row align="center" justify="center">
        <v-col
          cols="6"
          align-self="center"
          class="d-flex text-h3 justify-center"
        >
          <div class="mr-8">404</div>
          <div>查無此學校</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Swiper from 'swiper'
import { countdownReload } from '@/shared/utils'
import solarMakeEnergy from './solarMakeEnergy'
import useEnergy from './useEnergy'
import energyCompare from './energyCompare'
import reduceCO2 from './reduceCO2'
import energyConservation from './energyConservation'
import School from '@/api/admin/School'
// import Meter from '@/api/ems/Meter'

export default {
  name: undefined,
  mixins: [],
  components: {
    solarMakeEnergy,
    useEnergy,
    energyCompare,
    reduceCO2,
    energyConservation
  },
  props: {
    schoolCode: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      swiper: null,
      timer: null,
      apiQueue: [],
      apiQueueRuning: false,
      schoolInfo: {},
      loadingSchoolInfo: false,
      loadingData: false
    }
  },
  computed: {
    ...mapGetters({
      realtimePV: 'schoolDashboard/realtimePV',
      whetherSolarMeter: 'schoolDashboard/whetherSolarMeter'
    })
  },
  watch: {
    realtimePV(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.initSlide()
      }
    },
    apiQueue(newVal) {
      this.runAPIQueue()
      if (!newVal.length) this.loadingData = false
    },
    loadingData(newVal, oldVal) {
      if (!newVal && oldVal) {
        // 自動換頁
        this.timer = countdownReload(this.slideNext, 15)
        this.initSlide()

        // 更新時間 每小時更新一次
        this.getServerTime()
        this.serverTimer = countdownReload(this.getServerTime, 60 * 60)
      }
    }
  },
  created() {},
  async mounted() {
    await this.getSchool()
    await this.checkSolarMeter()
    this.loadingData = true
    setTimeout(() => {
      this.loadingData = false
    }, 5000)
    // API 資料更新 每分鐘更新一次
    this.storeApiData()
    this.timerAPI = countdownReload(this.storeApiData, 60)
  },
  destroyed() {
    clearInterval(this.timerAPI)
    clearInterval(this.timer)
    clearInterval(this.serverTimer)
  },
  methods: {
    ...mapActions({
      setSchoolId: 'schoolDashboard/setSchoolId',
      getWeather: 'schoolDashboard/getWeather',
      getPV: 'schoolDashboard/getPV',
      getSolar: 'schoolDashboard/getSolar',
      getEnergy: 'schoolDashboard/getEnergy',
      getACEnergy: 'schoolDashboard/getACEnergy',
      getCO2: 'schoolDashboard/getCO2',
      getRealtimePV: 'schoolDashboard/getRealtimePV',
      setWhetherSolarMeter: 'schoolDashboard/setWhetherSolarMeter',
      getServerTime: 'schoolDashboard/getServerTime'
    }),
    async getSchool() {
      try {
        this.loadingSchoolInfo = true
        const { data } = await School.codeToId(this.schoolCode)
        this.schoolInfo = data
        if (this.schoolInfo.schoolId) {
          this.setSchoolId(this.schoolInfo.schoolId)
        }
      } catch (error) {
        console.error(error.response)
      } finally {
        this.loadingSchoolInfo = false
      }
    },
    async checkSolarMeter() {
      try {
        // const { data: schoolMeters } = await Meter.getSchoolMeters(
        //   this.schoolInfo.schoolId
        // )
        // console.log(schoolMeters)
        // if (schoolMeters.find((meter) => meter.type === 'SOLARMETER'))
        if (this.schoolCode === '213618') this.setWhetherSolarMeter(false)
        else this.setWhetherSolarMeter(true)
      } catch (error) {
        console.error(error.response)
      }
    },
    storeApiData() {
      this.apiQueue.push(() => this.getWeather())
      this.apiQueue.push(() => this.getPV())
      this.apiQueue.push(() => this.getSolar())
      this.apiQueue.push(() => this.getEnergy())
      this.apiQueue.push(() => this.getACEnergy())
      this.apiQueue.push(() => this.getCO2())
      this.apiQueue.push(() => this.getRealtimePV())
    },
    initSlide() {
      this.$nextTick(() => {
        this.swiper = new Swiper('.swiper-container', {
          on: {
            init: function () {
              console.log('swiper initialized')
            }
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true
          }
        })
      })
    },
    slideNext() {
      if (!this.swiper) {
        return
      }
      if (this.swiper.slides.length === this.swiper.snapIndex + 1) {
        this.swiper.slideTo(0)
      } else {
        this.swiper.slideNext()
      }
    },
    runAPIQueue() {
      if (!this.apiQueueRuning && this.apiQueue && this.apiQueue.length > 0) {
        this.apiQueueRuning = true
        this.apiQueue[0]().finally(() => {
          this.apiQueue.shift()
          this.apiQueueRuning = false
          this.runAPIQueue()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~swiper/swiper.scss';
.page404 {
  height: calc(100vh - 64px);
}
</style>
