<template>
  <infoTemplate
    :infoTitleColor="infoTitleColor"
    :infoTitleBackgroundColor="infoTitleBackgroundColor"
  >
    <template v-slot:infoBox>
      <solarBox v-if="whetherSolarMeter" />
      <v-divider style="margin: 1rem auto" v-if="whetherSolarMeter"></v-divider>
      <acBox />
    </template>
  </infoTemplate>
</template>

<script>
import infoTemplate from './infoTemplate'
import solarBox from '../infoBox/solarBox'
import acBox from '../infoBox/acBox'
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {
    infoTemplate,
    solarBox,
    acBox
  },
  props: {
    infoTitleColor: {
      type: String,
      default: '#383838'
    },
    infoTitleBackgroundColor: {
      type: String,
      default: '#7de8b3'
    }
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      whetherSolarMeter: 'schoolDashboard/whetherSolarMeter'
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-title {
  color: #683e0c !important;
}
.info-box {
  .main-title {
    font-size: 1.125rem;
    padding: 0.5vw 0;
  }
}
</style>
