<template>
  <div class="main-canvas">
    <div class="title font-weight-bold text-h3 text-center">
      學校現在吃掉多少電了？
    </div>
    <img
      class="solar"
      :src="require('@/assets/image/schoolDashboard/group-64.svg')"
    />
    <div class="message-box">
      <div>
        大家上課要用電，現在學校正消耗
        <span style="color: #f15a24; font-size: 2rem">{{
          Object.keys(energy).length === 0 || energy.demand === null
            ? '--'
            : numeral(energy.demand).format('0,0')
        }}</span>
        kW ！
      </div>
    </div>
    <img
      class="message-box-triangle"
      :src="require('@/assets/image/schoolDashboard/path-80.svg')"
    />
    <img
      class="battery-bg"
      :src="require('@/assets/image/schoolDashboard/group-127.png')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      energy: 'schoolDashboard/energy'
    })
  }
}
</script>

<style lang="scss" scoped>
.main-card {
  position: relative;
  .solar {
    position: absolute;
    top: 22vw;
    left: 46vw;
    z-index: 1000;
    width: 40vw;
  }
  .message-box {
    position: absolute;
    top: 15vw;
    left: 34vw;
    z-index: 1000;
    width: 27vw;
    font-size: 2.1vw;
  }
  .message-box-triangle {
    position: absolute;
    top: 24vw;
    left: 46vw;
    z-index: 1001;
    width: 9vw;
  }
  .battery-bg {
    position: absolute;
    top: 10vw;
    left: 0;
    z-index: 900;
    width: 46vw;
  }
  .title {
    color: #0c5668;
  }
}
</style>
