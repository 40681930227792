<template>
  <div class="main-canvas">
    <div class="title font-weight-bold text-h3 text-center">
      太陽發的電還剩多少？
    </div>
    <img
      class="solar"
      :src="require('@/assets/image/schoolDashboard/Group 163.svg')"
    />
    <template v-if="data.solar > data.ac + data.power">
      <!-- 太陽>冷氣+既設 -->
      <energyCompareLibra001 v-model="data" />
    </template>
    <template
      v-else-if="data.ac + data.power > data.solar && data.solar > data.power"
    >
      <!-- 冷氣+既設>太陽>冷氣 -->
      <energyCompareLibra002 v-model="data" />
    </template>
    <template v-else>
      <!-- 冷氣>太陽 -->
      <energyCompareLibra003 v-model="data" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import energyCompareLibra001 from './energyCompareLibra/001'
import energyCompareLibra002 from './energyCompareLibra/002'
import energyCompareLibra003 from './energyCompareLibra/003'

export default {
  name: undefined,
  mixins: [],
  components: {
    energyCompareLibra001,
    energyCompareLibra002,
    energyCompareLibra003
  },
  props: {},
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      solar: 'schoolDashboard/solar',
      realtimePV: 'schoolDashboard/realtimePV'
    }),
    data() {
      return {
        ac:
          !this.realtimePV || this.realtimePV.acEnergyPerMin === null
            ? '--'
            : this.realtimePV.acEnergyPerMin,
        power:
          !this.realtimePV || this.realtimePV.schEnergyPerMin === null
            ? '--'
            : this.realtimePV.schEnergyPerMin,
        solar:
          !this.solar || this.solar.energyPerMin === null
            ? '--'
            : this.solar.energyPerMin
      }
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-card::v-deep {
  position: relative;
  .solar {
    position: absolute;
    top: 22vw;
    left: 46vw;
    z-index: 1000;
    width: 40vw;
  }
  .title {
    color: #107766;
  }
  .energy-value {
    position: absolute;
    width: 6.5vw;
    height: 6.5vw;
    background: radial-gradient(
      ellipse at center,
      #fff 0%,
      #fff 50%,
      #fff0 50%,
      #fff0 55%,
      #fff 55%,
      #fff 60%,
      #fff0 60%,
      #fff0 100%
    );
    z-index: 1006;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 1.6vw;
    line-height: 1.3;
    .unit {
      display: block;
      font-size: 1vw;
      line-height: 1;
    }
  }
}
</style>
