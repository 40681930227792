<template>
  <div class="info-box">
    <div class="main-title">學校已經消耗多少電呢?</div>
    <v-row>
      <v-col cols="auto">今天耗電：</v-col
      ><v-col
        >{{
          Object.keys(energy).length === 0 || energy.energyDay === null
            ? '--'
            : numeral(energy.energyDay).format('0,0')
        }}
        度電</v-col
      >
      <v-col cols="12" class="pa-0"></v-col>
      <v-col cols="auto">本月耗電：</v-col
      ><v-col
        >{{
          Object.keys(energy).length === 0 || energy.energyMon === null
            ? '--'
            : numeral(energy.energyMon).format('0,0')
        }}
        度電</v-col
      >
      <v-col cols="12" class="pa-0"></v-col>
      <v-col cols="auto">今年耗電：</v-col
      ><v-col
        >{{
          Object.keys(energy).length === 0 || energy.energyYear === null
            ? '--'
            : numeral(energy.energyYear).format('0,0')
        }}
        度電</v-col
      >
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      energy: 'schoolDashboard/energy'
    })
  }
}
</script>
