<template>
  <mainTemplate>
    <template v-slot:infoCard>
      <energyInfo class="info-card" />
    </template>
    <template v-slot:mainCard>
      <useEnergy class="main-card" />
    </template>
  </mainTemplate>
</template>

<script>
import mainTemplate from './mainTemplate'
import energyInfo from './card/energyInfo'
import useEnergy from './mainCanvas/useEnergy'

export default {
  name: undefined,
  mixins: [],
  components: {
    energyInfo,
    useEnergy,
    mainTemplate
  },
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.schoolDashboard-card::v-deep {
  background-color: #e6f4f4;
  .v-sheet--outlined {
    border: 0.1rem solid #bbe8e8;
  }
  .main-title {
    color: #0c5668;
  }
}
</style>
