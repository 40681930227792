<template>
  <infoTemplate infoTitleColor="#383838" infoTitleBackgroundColor="#bbe8e8">
    <template v-slot:infoBox>
      <settingsBox v-if="whetherSolarMeter" />
      <v-divider style="margin: 1rem auto" v-if="whetherSolarMeter"></v-divider>
      <energyBox />
    </template>
  </infoTemplate>
</template>

<script>
import infoTemplate from './infoTemplate'
import settingsBox from '../infoBox/settingsBox'
import energyBox from '../infoBox/energyBox'
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {
    infoTemplate,
    settingsBox,
    energyBox
  },
  props: {},
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      whetherSolarMeter: 'schoolDashboard/whetherSolarMeter'
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-title {
  color: #683e0c !important;
}
.info-box {
  .main-title {
    font-size: 1.125rem;
    padding: 0.5vw 0;
  }
}
</style>
