<template>
  <div class="main-canvas">
    <div class="title font-weight-bold text-h3 text-center">
      我們今天減少多少CO<sub>2</sub>？
    </div>
    <img
      class="solar"
      :src="require('@/assets/image/schoolDashboard/Group 163.svg')"
    />
    <div class="message-box">
      <div>
        今天發的電減少了
        <span style="color: #f15a24; font-size: 2rem">{{
          CO2.dailyCo2 === null ? '--' : numeral(CO2.dailyCo2).format('0,0')
        }}</span>
        KG的CO<sub>2</sub>！等於種了{{
          CO2.dailyTree === null ? '--' : numeral(CO2.dailyTree).format('0.0')
        }}棵樹。
      </div>
    </div>
    <img
      class="message-box-triangle"
      :src="require('@/assets/image/schoolDashboard/path-80.svg')"
    />
    <img
      class="trees"
      :src="require('@/assets/image/schoolDashboard/Group 211.svg')"
    />
    <div class="trees-value">×{{ numeral(CO2.dailyTree).format('0.0') }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      CO2: 'schoolDashboard/CO2'
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-card {
  position: relative;
  .solar {
    position: absolute;
    top: 22vw;
    left: 46vw;
    z-index: 1000;
    width: 40vw;
  }
  .message-box {
    position: absolute;
    top: 10vw;
    left: 13vw;
    z-index: 1000;
    width: 33vw;
    font-size: 2.1vw;
  }
  .message-box-triangle {
    position: absolute;
    top: 19vw;
    left: 39vw;
    z-index: 1001;
    width: 9vw;
  }
  .trees {
    position: absolute;
    top: 24vw;
    left: 0;
    z-index: 900;
    width: 25vw;
  }
  .trees-value {
    position: absolute;
    top: 31vw;
    left: 27vw;
    z-index: 900;
    color: #005272;
    font-size: 5rem;
    font-weight: bolder;
  }
  .title {
    color: #005272;
  }
}
</style>
