<template>
  <div class="info-box">
    <div class="main-title">本校學校總表裝置</div>
    <v-row>
      <v-col cols="auto">學校總表契約容量：</v-col
      ><v-col>
        {{
          Object.keys(solar).length === 0 || solar.schoolMeterCapacity === null
            ? '--'
            : numeral(solar.schoolMeterCapacity).format('0[.]00')
        }}
        kW</v-col
      >
      <v-col cols="12" class="pa-0"></v-col>
      <v-col cols="auto">冷氣總表契約容量：</v-col
      ><v-col>
        {{
          Object.keys(solar).length === 0 || solar.acMeterCapacity === null
            ? '--'
            : numeral(solar.acMeterCapacity).format('0[.]00')
        }}
        kW</v-col
      >
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      solar: 'schoolDashboard/solar'
    })
  }
}
</script>

<style></style>
